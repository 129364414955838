import request from '@/utils/request'
// 接送人列表
export function getlist(query) {
  return request({
    url: '/mobile/attendance/parents',
    method: 'get',
    params: query
  })
}

// 添加接送人
export function addpick(data) {
  return request({
    url: '/mobile/attendance/saveParent',
    method: 'post',
    data
  })
}

// 接送人详情
export function pickinfo(query) {
  return request({
    url: '/mobile/user/parentDetail?parent_id=' + query,
    method: 'get',
    query
  })
}

// 删除接送人 
export function delparent(data) {
  return request({
    url: '/mobile/attendance/delParent',
    method: 'post',
    data
  })
}

// http://www.test-tp6.com/mobile/user/studentDetail?parent_id=1165
// 获取学生详情 
export function edit_info(query) {
  return request({
    url: '/mobile/user/studentDetail?student_id='+query,
    method: 'get',
    query
  })
}

// 编辑学生信息 
export function edit_student(data) {
  return request({
    url: '/mobile/user/update',
    method: 'post',
    data
  })
}

// 上传图片 
export function upload(data) {
  return request({
    url: 'mobile/upload/upload',
    method: 'post',
    data
  })
}

export function feeItem(data) {
  return request({
    url: 'mobile/pay/feeItem',
    method: 'post',
    data
  })
}
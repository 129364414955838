<template>
  <div class="bod">
    <!--  -->
    <div class="head">
      <van-image round width="19vw" height="19vw" :src="oss_url + photo" v-if="photo" />
      <van-image round width="19vw" height="19vw" :src=" oss_url + nohead " v-else />
      <div class="user">
        <!-- 用户名 + 开通状态图标 -->
        <div>
          <span>{{ name }}</span>
          <img src="@/assets/member/Group2@2x.png" alt="" v-if="is_pay" />
          <img src="@/assets/member/Group1@2x.png" alt="" v-else />
        </div>
        <div>{{ mobile }}</div>
        <div v-if="expiration_time">有效期至：{{ expiration_time }}</div>
      </div>
    </div>
    <!--  -->
    <div class="main">
      <div class="tag">
        <span>会员权益</span>
      </div>
      <div class="tre">
        <div>
          <div><img src="../../assets/member/icon_xiaoxi@2x.png" alt="" /></div>
          <div>推送通知</div>
          <div>推送考勤,接送,班级<br>通知相关内容</div>
        </div>
        <div>
          <div>
            <img src="../../assets/member/icon_banjiquan@2x.png" alt="" />
          </div>
          <div>班级圈</div>
          <div>了解宝贝在校的<br>点点滴滴</div>
        </div>
        <div>
          <div>
            <img src="../../assets/member/icon_jiesong@2x.png" alt="" />
          </div>
          <div>接送管理</div>
          <div>可添加家庭成员<br>接送宝贝</div>
        </div>
      </div>
      <div class="tag2">
        <span>开通会员</span>
      </div>
      <div class="fee">
        <div><span>￥</span><span>{{ fee }}</span></div>
        <div>
          <div>{{ fee_item_name }}</div>
          <div>会员过期自动失效</div>
        </div>
      </div>
    </div>
    <div class="btn" @click="handleOrder()">
        立即开通
    </div>
  </div>

</template>

<script>

import { Pay, feeItem } from "@/api/pay";
import { edit_info } from "@/api/pickup/list";
import { getUserId, getMobile, getOpenId } from "../../utils/user.js";
import { Notify, Dialog } from "vant";

export default {
  data() {
    return {
      text: "",
      columns: [],
      fileList: [],
      id: "",
      oss_url: process.env.VUE_APP_OSS_URL,
      photo: "",
      name: "",
      mobile: "",
      fee: '',
      fee_item_id: '',
      nohead: "kindergarten/photo/20230830/fb171e521ef772fa964916b0fb1748f687771e91.png",
      fee_type: '',
      is_pay: false,
      fee_item_name: '',
      expiration_date: '',
    };
  },
  created() {
    this.id = getUserId();
    this.mobile = getMobile();
    this.getFeeItem();
    this.getinfo();
  },
  beforeCreate() {
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#F8F8F8");
  },
  methods: {
    getinfo() {
      edit_info(this.id).then((res) => {
        this.expiration_date = res.data.expiration_date
        this.photo = res.data.face;
        this.name = res.data.user_name;
        this.is_pay = res.data.expiration_date;
        this.expiration_time = res.data.expiration_time;
      });
    },
    getFeeItem() {
      feeItem().then((res) => {
        this.fee = res.data.fee;
        this.fee_item_id = res.data.id;
        this.fee_item_name = res.data.fee_name;
      })
    },
    onBridgeReady(appid, timestamp, nonce, pack, paysign) {
      WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          appId: appid,
          timeStamp: timestamp,
          nonceStr: nonce,
          package: pack,
          signType: "RSA",
          paySign: paysign,
        },
        function (res) {
          if (res.err_msg === "get_brand_wcpay_request:ok") {
            // 使用以上方式判断前端返回,微信团队郑重提示：
            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
            this.$router.push('/student_index')
            Notify({ type: "success", message: "支付成功" });
          } else if (res.err_msg === 'get_brand_wcpay_request:cancel') {
            Notify({ type: "warning", message: "取消支付" });
          } else if (res.err_msg === 'get_brand_wcpay_request:fail') {
            Notify({ type: "warning", message: "支付失败" });
          }
        }
      );
    },
    //提交申请
    handleOrder() {
      if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener(
            "WeixinJSBridgeReady",
            onBridgeReady,
            false
          );
        } else if (document.attachEvent) {
          document.attachEvent("WeixinJSBridgeReady", onBridgeReady);
          document.attachEvent("onWeixinJSBridgeReady", onBridgeReady);
        }
      } else {
        if (this.fee_item_id === '') {
          Notify({ type: "warning", message: "收费项不存在" });
          return;
        }

        if (this.is_pay) {

          Dialog.confirm({
            message:
                '您已开通，是否继续续费？',
          }).then(() => {
            Pay({'openid': getOpenId(), 'fee_item_id': this.fee_item_id }).then((res) => {
              if (res.code === 200) {
                var data = JSON.parse(res.data);
                this.onBridgeReady(
                    data.appId,
                    data.timeStamp,
                    data.nonceStr,
                    data.package,
                    data.paySign
                )
              }
            });
          }).catch(() => {});

        } else {

          Pay({'openid': getOpenId(), 'fee_item_id': this.fee_item_id }).then((res) => {
            if (res.code === 200) {
              var data = JSON.parse(res.data);
              this.onBridgeReady(
                  data.appId,
                  data.timeStamp,
                  data.nonceStr,
                  data.package,
                  data.paySign
              )
            }
          });
        }

      }
    },
  },
};
</script>

<style lang="less" scoped>
.bod {
  background-image: url(../../assets/member/BG@2x.png);
  background-size: 100%;
  background-repeat: no-repeat;
  padding: 3vw;
}

.head {
  display: flex;
  align-items: center;
  margin-top: 8vw;
}

.user {
  margin-left: 3vw;

  :first-child {
    color: white;
    font-size: 5vw;
    margin-bottom: 2.5vw;
    margin-top: 1vw;

    img {
      width: 13vw;
      margin-left: 2vw;
      margin-bottom: -0.4vw;
    }
  }

  :nth-child(2) {
    color: #fafafaae;
    font-size: 3.5vw;
    margin-bottom: 1vw;
  }
  :nth-child(3){
    font-size: 3.5vw;
    color:#fafafaae;
  }
}

.main {
  width: 100%;
  background-color: white;
  border-radius: 20px;
  margin-top: 7vw;
  padding: 3vw;
  box-sizing: border-box;

  .tag {
    text-align: center;
    margin-top: 5vw;
    font-size: 4.5vw;
    font-weight: 600;
    font-family:"Microsoft JhengHei";
    color: #444444;
    background-image: url(../../assets/member/img_biaoti@2x.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 40% 60%;
  }

  .tag2 {
    text-align: center;
    margin-top: 10vw;
    font-size: 4.5vw;
    font-weight: bold;
    font-family:"Microsoft JhengHei";
    color: #444444;
    background-image: url(../../assets/member/img_biaoti@2x.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 40% 60%;
  }

  .tre {
    margin-top: 8vw;
    display: flex;
    justify-content: space-between;

    img {
      width: 15vw;
      margin-bottom: 1vw;
    }

    >div {
      text-align: center;
      width: 31%;

      :nth-child(2) {
        font-size: 3.5vw;
        margin-bottom: 1vw;
      }

      :nth-child(3) {
        font-size: 0.7rem;
        line-height: 5vw;
        color: #999;
      }
    }
  }

  .fee {
    width: 100%;
    background-image: url(../../assets/member/bg_jiage@2x.png);
    background-repeat: no-repeat;
    background-size: 100%;
    height: 21vw;
    display: flex;
    align-items: center;
    margin-bottom: 10vw;
    margin-top: 5vw;

    >div:first-child {
      font-size: 9vw;
      color: #5380ff;
      margin: 0 5vw 0 5vw;

      span:first-child {
        font-size: 4vw;
      }
    }

    >div:nth-child(2) {
      >div:first-child {
        color: #444;
        font-size: 4vw;
        font-weight: bold;
        margin-bottom: 3vw;
      }

      >div:nth-child(2) {
        font-size: 3vw;
        color: #999;
      }
    }
  }
}

.btn {
  width: 94%;
  position: fixed;
  bottom: 10px;
  background-color: #5380ff;
  text-align: center;
  color: white;
  font-size: 4vw;
  padding: 2vw 0 2vw 0;
  box-sizing: border-box;
  border-radius: 8px;
}

/deep/ .van-image--round {
  border: 2px solid white;
}

/*  */
.box {
  width: 21.56rem;
  margin: auto;
  margin: 0 0.94rem;
}

/deep/ .van-cell {
  font-size: 0.88rem;
  color: #333;
  font-weight: 500;
}

/deep/ .van-button--block {
  margin-top: 1.8rem;
}
</style>
